<template>
  <div class="w-full max-h-screen bg-background flex flex-col h-full">
    <page-header
        backTo="contacts"
        title="Equipment Detail"
        :enableLogicalBack="true"
    >

      <button
          @click="createJobSheet"
          type="button"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/>
          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"/>
          <path d="M9 12h6"/>
          <path d="M9 16h6"/>
        </svg>
        <span>Create Job Sheet</span>
      </button>
      <!-- <button
        @click="changeViewMode"
        type="button"
        class="flex items-center bg-teal-200 border hover:bg-teal-300 border-teal-400 hover:border-teal-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <rect width="22" height="14" x="1" y="5" rx="7" ry="7" />
          <circle cx="16" cy="12" r="3" />
        </svg>
        <span>Sales view</span>
      </button> -->
      <!-- <button
        @click="showingCreateTicketPanel = true"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path
            d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
          />
        </svg>
        <span>Create Ticket</span>
      </button> -->
    </page-header>
    <main class="p-6 bg-background flex-1 overflow-y-scroll h-full">
      <div v-if="equipmentItem" class="bg-white p-5 rounded shadow-md">
        <div class="flex">
          <div class="w-2/3">
            <div class="flex items-center mb-4">
              <div
                  class="inline-flex items-center rounded-lg bg-orange-200 py-1 px-2 mr-5"
              >
                <svg
                    class="text-orange-700 stroke-current h-8 w-8 mr-2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M16.5 9.4l-9-5.19M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"
                  />
                  <path d="M3.27 6.96L12 12.01l8.73-5.05M12 22.08V12"/>
                </svg>
                <p class="text-orange-700 rounded-lg text-2xl">
                  {{ equipmentItem.product_code }}
                </p>
              </div>
              <h2 class="text-3xl">
                {{ equipmentItem.name }}
              </h2>
            </div>

            <!-- <div v-if="contact.people && contact.people.length > 0">
                  <div
                    v-if="
                      contact.people[0].firstname || contact.people[0].lastname
                    "
                    class="flex items-center py-1"
                  >
                    <svg
                      class="text-orange-700 stroke-current h-5 w-5 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                      <circle cx="12" cy="7" r="4" />
                    </svg>
                    <p
                      v-if="contact.people[0].job_title"
                      class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2"
                    >
                      {{ contact.people[0].job_title }}
                    </p>
                    <p class="rounded-lg px-2 py-1">
                      <span v-if="contact.people[0].firstname">{{
                        contact.people[0].firstname + " "
                      }}</span>
                      <span v-if="contact.people[0].lastname">{{
                        contact.people[0].lastname
                      }}</span>
                    </p>
                  </div>
                  <div
                    v-if="contact.people[0].email"
                    class="flex items-center py-1"
                  >
                    <svg
                      class="text-orange-700 stroke-current h-5 w-5 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                      />
                      <path d="M22 6l-10 7L2 6" />
                    </svg>
                    <a
                      class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                      :href="'mailto:' + contact.people[0].email"
                      >{{ contact.people[0].email }}</a
                    >
                  </div>
                  <div
                    v-if="
                      contact.people[0].mobile || contact.people[0].telephone
                    "
                    class="flex items-center py-1"
                  >
                    <svg
                      class="text-orange-700 stroke-current h-5 w-5 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"
                      />
                    </svg>
                    <a
                      v-if="contact.people[0].mobile"
                      class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                      :href="'tel:' + contact.people[0].mobile"
                      >{{ contact.people[0].mobile }}</a
                    >
                    <a
                      v-if="contact.people[0].telephone"
                      class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                      :href="'tel:' + contact.people[0].telephone"
                      >{{ contact.people[0].telephone }}</a
                    >
                  </div>

                  <div v-if="contact.website" class="flex items-center py-1">
                    <svg
                      class="text-orange-700 stroke-current h-5 w-5 mr-2"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                    >
                      <circle cx="12" cy="12" r="10" />
                      <path
                        d="M2 12h20M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10z"
                      />
                    </svg>
                    <a
                      class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                      :href="'//' + contact.website"
                      target="_blank"
                    >
                      {{ contact.website }}
                    </a>
                  </div>
                </div> -->
          </div>
          <div class="w-1/3 flex items-start justify-end space-x-2">
            <div
                :class="
                `${
                  equipmentItem.is_operational
                    ? 'border-blue-600 text-blue-700'
                    : 'border-red-600 text-red-700'
                } inline-flex items-center border-2 font-bold uppercase text-sm pl-1 pr-2 py-1 leading-none rounded-lg select-none`
              "
            >
              <svg
                  class="stroke-current h-5 w-5 mr-1"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
              >
                <path v-if="equipmentItem.is_operational" d="M20 6L9 17l-5-5"/>
                <path v-else d="M18 6 6 18M6 6l12 12"/>
              </svg>
              <span>{{
                  equipmentItem.is_operational
                      ? "Is Operational"
                      : "Not Operational"
                }}</span>
            </div>
            <div
                v-if="equipmentItem.is_loan_unit"
                :class="
                `${
                  equipmentItem.is_loan_unit
                    ? 'border-blue-600 text-blue-700'
                    : 'border-red-600 text-red-700'
                } inline-flex items-center border-2 font-bold uppercase text-sm pl-1 pr-2 py-1 leading-none rounded-lg select-none`
              "
            >
              <svg
                  class="stroke-current h-5 w-5 mr-1"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5"/>
              </svg>
              <span>Is Loan Unit</span>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="border border-primary rounded p-4">
            <header-token
                label="Serial Number"
                :value="equipmentItem.serial_number"
            />
            <div v-if="equipmentItem.seller_agent" class="flex items-center py-1">
              <div class="w-1/5 text-center rounded-lg bg-orange-200 py-1 px-2">
                <span class="text-orange-700 text-sm">Sold By Agent</span>
              </div>
              <router-link
                  v-if="equipmentItem.seller_agent.contact_id"
                  :to="`/contacts/${equipmentItem.seller_agent.contact_id}`"
                  class="rounded-lg px-2 py-1 underline"
              >
                {{ equipmentItem.seller_agent.name }}
              </router-link>
            </div>

            <div v-if="equipmentItem.service_agent" class="flex items-center py-1">
              <div class="w-1/5 text-center rounded-lg bg-orange-200 py-1 px-2">
                <span class="text-orange-700 text-sm">Serviced By Agent</span>
              </div>
              <router-link
                  v-if="equipmentItem.service_agent.contact_id"
                  :to="`/contacts/${equipmentItem.service_agent.contact_id}`"
                  class="rounded-lg px-2 py-1 underline"
              >
                {{ equipmentItem.service_agent.name }}
              </router-link>
            </div>

            <header-token
                label="Equipment Code"
                :value="equipmentItem.metadata && equipmentItem.metadata.legacy_database_id ? equipmentItem.metadata.legacy_database_id : 'N/A'"
            />

            <header-token
                label="Service Interval"
                :value="equipmentItem.service_interval ? `${equipmentItem.service_interval} months` : 'N/A'"
            />

            <header-token
                label="Calibration Interval"
                :value="equipmentItem.calibration_interval ? `${equipmentItem.calibration_interval} months` : 'N/A'"
            />

          </div>
          <div class="border border-primary rounded p-4">
            <div class="flex items-center py-1">
              <div class="w-1/5 text-center rounded-lg bg-orange-200 py-1 px-2">
                <span class="text-orange-700 text-sm">Owner</span>
              </div>
              <router-link
                  :to="`/contacts/${equipmentItem.owner.contact_id}`"
                  class="rounded-lg px-2 py-1 underline"
              >
                {{ equipmentItem.owner.name }}
              </router-link>
              <div
                  v-if="equipmentItem.owner.is_on_hold"
                  class="inline-flex items-center rounded-lg bg-red-200 py-1 px-2"
              >
                <svg
                    class="stroke-current text-red-700 h-5 w-5 mr-1"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                >
                  <path
                      d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0zM12 9v4M12 17h.01"
                  />
                </svg>
                <span class="text-red-700 text-sm">On Hold</span>
              </div>
            </div>
            <div class="flex items-center py-1">
              <div class="w-1/5 text-center rounded-lg bg-orange-200 py-1 px-2">
                <span class="text-orange-700 text-sm">Address</span>
              </div>
              <p class="px-2 py-1">
                {{
                  equipmentItem.owner.addresses[0].address1 &&
                  `${equipmentItem.owner.addresses[0].address1}, `
                }}
                {{
                  equipmentItem.owner.addresses[0].address2 &&
                  `${equipmentItem.owner.addresses[0].address2}, `
                }}
                {{
                  equipmentItem.owner.addresses[0].city &&
                  `${equipmentItem.owner.addresses[0].city}, `
                }}
                {{
                  equipmentItem.owner.addresses[0].county &&
                  `${equipmentItem.owner.addresses[0].county}, `
                }}
                {{
                  equipmentItem.owner.addresses[0].postcode &&
                  `${equipmentItem.owner.addresses[0].postcode}, `
                }}
                {{
                  equipmentItem.owner.addresses[0].country &&
                  equipmentItem.owner.addresses[0].country
                }}
              </p>
            </div>
            <header-token label="Location" :value="equipmentItem.location"/>
            <header-token
                label="Sold Condition"
                :value="equipmentItem.sold_condition"
            />
            <header-token
                label="Delivery Date"
                :value="
                equipmentItem.delivery_date
                  ? $moment
                      .unix(equipmentItem.delivery_date)
                      .format('DD/MM/YYYY')
                  : ''
              "
            />
            <header-token
                label="Warranty Expiry"
                :value="
                equipmentItem.warranty_expiry
                  ? $moment
                      .unix(equipmentItem.warranty_expiry)
                      .format('DD/MM/YYYY')
                  : ''
              "
            />
          </div>
        </div>
      </div>
      <!-- <div v-if="equipmentItem" class="mt-8">
        {{ JSON.stringify(equipmentItem) }}
      </div> -->
      <div v-if="equipmentItem" class="bg-white p-5 rounded shadow-md mt-6">
        <div class="w-full mb-6">
          <div class="flex -mx-2 mr-2">
            <router-link
                to="#calibration-history"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="
                $route.hash == '' || $route.hash == '#calibration-history'
                  ? 'bg-orange-300 text-orange-900 border-orange-300'
                  : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
              "
            >
              <span>Calibration History</span>
            </router-link>
            <router-link
                to="#job-history"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="
                $route.hash == '#job-history'
                  ? 'bg-orange-300 text-orange-900 border-orange-300'
                  : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
              "
            >
              <span>Job History</span>
            </router-link>
            <router-link
                to="#contract-history"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="
                $route.hash == '#contract-history'
                  ? 'bg-orange-300 text-orange-900 border-orange-300'
                  : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
              "
            >
              <span>Contract History</span>
            </router-link>
            <router-link
                to="#comments"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="
                $route.hash == '#comments'
                  ? 'bg-orange-300 text-orange-900 border-orange-300'
                  : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
              "
            >
              <span>Comments</span>
            </router-link>
            <router-link
                to="#loan-history"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="
                $route.hash == '#loan-history'
                  ? 'bg-orange-300 text-orange-900 border-orange-300'
                  : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
              "
            >
              <span>Loan History</span>
            </router-link>
            <router-link
                to="#expiry-dates"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="
                $route.hash == '#expiry-dates'
                  ? 'bg-orange-300 text-orange-900 border-orange-300'
                  : 'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200'
              "
            >
              <span>Expiry Dates</span>
            </router-link>
          </div>
        </div>
        <div v-if="$route.hash == '' || $route.hash == '#calibration-history'">
          <CalibrationHistory :data="equipmentItem.calibrations"/>
        </div>
        <div v-if="$route.hash == '#job-history'">
          <JobHistory :data="[]"/>
        </div>
        <div v-if="$route.hash == '#contract-history'">
          <ContractHistory :data="[]"/>
        </div>
        <div v-if="$route.hash == '#comments'">
          <Comments
              :resourceType="'equipment'"
              :resourceId="this.equipmentItem.equipment_id"
          />
        </div>
        <div v-if="$route.hash == '#loan-history'">
          <LoanEquipmentHistory :loanEquipmentHistory="loanEquipmentHistory"/>
        </div>
        <div v-if="$route.hash == '#expiry-dates'">
          <ExpiryDates :data="equipmentItem" @expiryDatesUpdated="handleExpiryDatesUpdated"></ExpiryDates>
        </div>
      </div>
      <div v-if="viewState == 'Loading'" class="bg-white p-5 rounded shadow-md">
        <div class="flex items-center justify-center py-20">
          <spinner :color="'text-black'" :size="10"/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const CalibrationHistory = () =>
    import("@/components/Equipment/CalibrationHistory.vue");
const JobHistory = () => import("@/components/Equipment/JobHistory.vue");
const ContractHistory = () =>
    import("@/components/Equipment/ContractHistory.vue");
const Comments = () => import("@/components/Comments/Comments.vue");
const HeaderToken = () => import("@/components/Equipment/HeaderToken.vue");
const Spinner = () => import("@/components/Spinner.vue");
import LoanEquipmentHistory from '@/components/LoanEquipment/LoanEquipmentHistory.vue';

const ExpiryDates = () =>
    import("@/components/Equipment/ExpiryDates.vue");

export default {
  name: "EquipmentDetail",
  components: {
    PageHeader,
    CalibrationHistory,
    JobHistory,
    ContractHistory,
    Comments,
    HeaderToken,
    Spinner,
    LoanEquipmentHistory,
    ExpiryDates
  },
  props: {
    equipmentId: String,
  },
  data() {
    return {
      viewState: "Idle",
      equipmentItem: null,
      error: null,
      loanEquipmentHistory: []
    };
  },
  methods: {
    createJobSheet() {
      if (this.equipmentId) {
        this.$router.push({
          name: "engineer-job-sheet-create",
          query: {
            equipment_id: this.equipmentId
          },
        });
      }
    },

    LoadEquipment: async function () {
      this.viewState = "Loading";

      try {
        const equipmentItem = await this.EquipmentService.getEquipmentItemById(
            this.equipmentId
        );
        this.equipmentItem = equipmentItem;
        console.log(this.equipmentItem);

        if (equipmentItem.service_agent_id) {
          this.serviceAgent = await this.ContactService.getContactById(
              equipmentItem.service_agent_id
          );
        }

        if (equipmentItem.loan_equipments && equipmentItem.loan_equipments.length > 0) {
          this.loanEquipmentHistory = equipmentItem.loan_equipments;
        } else {
          this.loanEquipmentHistory = [];
        }

        this.viewState = "Idle";
      } catch (error) {
        this.error = error;
        console.log(error);
        this.viewState = "Error";
      }
    },

    async handleExpiryDatesUpdated(result) {
      await this.LoadEquipment();
    },
  },

  async mounted() {
    await this.LoadEquipment();
  },
};
</script>
